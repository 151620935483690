<template>
  <main class="full-page-wrapper server-error-container">
    <div class="svg-image">
      <img src="../assets/thumbnails/500.svg" />
    </div>

    <div class="message-box">
      <div class="content-box">
        <p class="-xxxltb">A server error has occurred.</p>
        <p class="-stb" style="text-align: left">
          We apologize for the inconvenience and are working to resolve the
          issue. If the problem persists, please contact our support team for
          assistance. <br />
        </p>
        <div class="buttons-con">
          <div class="action-link-wrap">
            <a class="link-button" @click="goHome()">Home Page</a>
          </div>
        </div>
        <a href="https://storyset.com/people" target="_blank" class="mention"
          >People illustrations by Storyset</a
        >
      </div>
    </div>
  </main>
</template>

<script>
export default {
  props: {
    err: {
      type: String,
      required: true,
    },
  },
  methods: {
    goHome() {
      this.$router.push({ name: "Home" });
    },
  },
  beforeCreate() {
    const err = parseInt(this.err);
    return err >= 500 && err < 600
      ? false
      : this.$router.push({ name: "Home" });
  },
  async mounted() {
    await this.$store.dispatch("clearStore");
  },
};
</script>

<style scoped>
.server-error-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-inline: 8px;
  /* gap: 64px; */
}
.svg-image {
  width: 100%;
  height: 100%;
  min-height: 420px;
  min-width: 420px;
  pointer-events: none;
  user-select: none;
}
.svg-image > img {
  width: 100%;
  height: 100%;
}
.message-box {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  color: #fff;
  font-weight: 300;
  letter-spacing: 2px;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.-xxxltb {
  text-align: left;
}

.content-box {
  display: flex;
  flex-flow: column;
  gap: 20px;
  padding-bottom: 8px;
}
.buttons-con .action-link-wrap a {
  background: var(--highlight);
  padding: 8px 25px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
  margin-right: 10px;
}
.buttons-con .action-link-wrap a:hover {
  background: var(--white1);
  color: var(--primary);
}
.action-link-wrap {
  display: flex;

  /* flex-flow: column; */
  gap: 20px;
}

.mention {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 6px;
  letter-spacing: 0;
  text-decoration: none;
  color: #fff;
  cursor: none;
}

@media only screen and (max-width: 699px) {
  .server-error-container {
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
    overflow: scroll;
    gap: 0px;
  }
  .svg-image {
    width: 100%;
    height: 400px;
  }
  .message-box {
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
  }

  .action-link-wrap {
    display: flex;
    width: 200px;
    flex-flow: column;
    gap: 20px;
  }
}
@media only screen and (max-height: 399px) {
  .server-error-container {
    align-items: flex-start;
  }
}
</style>
